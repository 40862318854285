import React, { useRef } from 'react';
import sectionimage from '../assets/card.png';

const Shop = () => {
  const sliderRef = useRef(null);

  const handleNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: window.innerWidth,
        behavior: 'smooth'
      });
    }
  };

  const handlePreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -window.innerWidth,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className='py-20 px-20 mt-10 mb-10 bg-purple-500 w-full h-full flex'>
      <div className="w-1/4 mr-20 " style={{height:"300px"}}>
        <div className="relative flex items-center justify-center">
          <h1 className="text-[10rem] text-white font-bold font-outline-2 absolute top-[-2rem] font-wixdisplay" style={{ zIndex: '1' }}>
            AIIRO
          </h1>
          <h1 className="text-[10rem] text-white font-bold font-outline-2 absolute top-[4rem] font-wixdisplay" style={{ zIndex: '2' }}>
            Shop
          </h1>
        </div>
      </div>
      <div className="w-3/4 relative">
        <div ref={sliderRef} className="flex overflow-x-hidden">
        <div className="relative bg-white p-8 shadow-md rounded-md mr-4" style={{ minWidth: "300px", height:'300px' }}>
          <div className="absolute top-0 left-0 w-full h-48 bg-cover bg-center rounded" style={{ backgroundImage: 'url("https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg")' }}></div>
          <div className="absolute bottom-0 left-0 w-full p-4">
            <p>This is the content of card 1.</p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Button</button>
          </div>
        </div>
        <div className="relative bg-white p-8 shadow-md rounded-md mr-4" style={{ minWidth: "300px", height:'300px' }}>
          <div className="absolute top-0 left-0 w-full h-48 bg-cover bg-center rounded" style={{ backgroundImage: 'url("https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg")' }}></div>
          <div className="absolute bottom-0 left-0 w-full p-4">
            <p>This is the content of card 1.</p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Button</button>
          </div>
        </div>
        <div className="relative bg-white p-8 shadow-md rounded-md mr-4" style={{ minWidth: "300px", height:'300px' }}>
          <div className="absolute top-0 left-0 w-full h-48 bg-cover bg-center rounded" style={{ backgroundImage: 'url("https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg")' }}></div>
          <div className="absolute bottom-0 left-0 w-full p-4">
            <p>This is the content of card 1.</p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Button</button>
          </div>
        </div>
        <div className="relative bg-white p-8 shadow-md rounded-md mr-4" style={{ minWidth: "300px", height:'300px' }}>
          <div className="absolute top-0 left-0 w-full h-48 bg-cover bg-center rounded" style={{ backgroundImage: 'url("https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg")' }}></div>
          <div className="absolute bottom-0 left-0 w-full p-4">
            <p>This is the content of card 1.</p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Button</button>
          </div>
        </div>
        <div className="relative bg-white p-8 shadow-md rounded-md mr-4" style={{ minWidth: "300px", height:'300px' }}>
          <div className="absolute top-0 left-0 w-full h-48 bg-cover bg-center rounded" style={{ backgroundImage: 'url("https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg")' }}></div>
          <div className="absolute bottom-0 left-0 w-full p-4">
            <p>This is the content of card 1.</p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Button</button>
          </div>
        </div>
        </div>
        <button
          className="absolute top-1/2 transform -translate-y-1/2 left-[-1rem] bg-white p-4 rounded-md shadow-md"
          onClick={handlePreviousSlide}
        >
          &lt;
        </button>
        <button
          className="absolute top-1/2 transform -translate-y-1/2 right-[-1rem] bg-white p-4 rounded-md shadow-md"
          onClick={handleNextSlide}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Shop;
