// src/App.js
import React, { useState, useEffect } from 'react';
import Navbar from './components/navbar';
import { CarouselCustomNavigation } from './components/carousel';
import Section from './components/section';
import Card from './components/card';
import Footer from './components/footer';
import Gallery from './components/Gallery';
import Shop from './components/shop';
import card1 from './assets/card1.jpg';
import card2 from './assets/card2.png';
import card3 from './assets/card3.png';
import card4 from './assets/card4.png';
import card5 from './assets/card5.png';
import card6 from './assets/card6.png';
import gallery1 from './assets/gallerylonjong1.jpg';
import gallery2 from './assets/gallerylonjong2.jpg';
import gallery3 from './assets/gallerylonjong3.jpg';
import gallery4 from './assets/gallerylonjong4.jpg';
import gallery5 from './assets/gallerylonjong5.jpg';
import gallery6 from './assets/gallerylonjong6.jpg';
import gallery7 from './assets/gallerylonjong7.jpeg';

import gallerykotak1 from './assets/gallerykotak1.jpg';
import gallerykotak2 from './assets/gallerykotak2.jpg';
import gallerykotak3 from './assets/gallerykotak3.jpg';
import gallerykotak4 from './assets/gallerykotak4.jpg';
import gallerykotak5 from './assets/gallerykotak5.jpeg';
import Contactus from './components/contactus';

function App() {
  // Sample data for cards
  const cardsData = [
    { title: 'Daweykunn', category: 'Category 1', description: 'Description for Card 1', imageSrc: card1, fb:'https://www.facebook.com/claravels', insta:'https://www.instagram.com/ifxxca', twitter: 'https://www.twitter.com/ifxxca', pixivlink: 'https://www.pixivlink.net/en/users/9395764' },
    { title: 'Vitotraps', category: 'Category 2', description: 'Description for Card 2', imageSrc: card2, fb:'https://www.facebook.com/claravels', insta:'https://www.instagram.com/ifxxca', twitter: 'https://www.twitter.com/ifxxca', pixivlink: 'https://www.pixivlink.net/en/users/9395764' },
    { title: 'Tokage88', category: 'Category 3', description: 'Description for Card 3', imageSrc: card3, fb:'https://www.facebook.com/claravels', insta:'https://www.instagram.com/ifxxca', twitter: 'https://www.twitter.com/ifxxca', pixivlink: 'https://www.pixivlink.net/en/users/9395764' },
    { title: 'Qweerin', category: 'Category 4', description: 'Description for Card 4', imageSrc: card4, fb:'https://www.facebook.com/claravels', insta:'https://www.instagram.com/ifxxca', twitter: 'https://www.twitter.com/ifxxca', pixivlink: 'https://www.pixivlink.net/en/users/9395764' },
    { title: 'Arikawarui', category: 'Category 5', description: 'Description for Card 5', imageSrc: card5, fb:'https://www.facebook.com/claravels', insta:'https://www.instagram.com/ifxxca', twitter: 'https://www.twitter.com/ifxxca', pixivlink: 'https://www.pixivlink.net/en/users/9395764' },
    { title: 'Her', category: 'Category 6', description: 'Description for Card 6', imageSrc: card6, fb:'https://www.facebook.com/claravels', insta:'https://www.instagram.com/ifxxca', twitter: 'https://www.twitter.com/ifxxca', pixivlink: 'https://www.pixivlink.net/en/users/9395764' },
  ];

    // State untuk menyimpan data kartu yang diacak
    const [shuffledCards, setShuffledCards] = useState([]);

    // Fungsi untuk mengacak urutan array
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };
  
    // Mengacak urutan kartu saat komponen pertama kali dimuat
    useEffect(() => {
      const shuffled = shuffleArray(cardsData);
      setShuffledCards(shuffled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // eslint-disable-next-line

  const galleryData = [
    [
      gallery3,
      gallery7,
      gallerykotak5
    ],
    [
      gallerykotak2,
      gallery1,
      gallerykotak1
    ],
    [
      gallery2,
      gallery4,
      gallerykotak3
    ],
    [
      gallery6,
      gallery5,
      gallerykotak4
    ]
  ];

  return (
    <div>
      <Navbar />
      <CarouselCustomNavigation />
      <Section />
      <div className='pt-10 font-bold text-center text-white text-9xl font-outline-2 font-wixdisplay'>
          OUR ARTIST
      </div>
      <div className="flex flex-wrap pt-20">
        {shuffledCards.slice(0, 3).map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
      <div className="flex flex-wrap ">
        {shuffledCards.slice(3, 6).map((card, index) => (
          <Card key={index + 3} {...card} />
        ))}
      </div>
      <Gallery gallery={galleryData} />
      <Shop />
      <Footer />
    </div>
  );
}

export default App;
  