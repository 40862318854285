import React from 'react';
import { motion } from 'framer-motion';

const Gallery = ({ gallery }) => {
  return (
    <div className="">
      <motion.div
        id="gallery"
        className="py-10 font-bold text-center text-white text-9xl font-outline-2 font-wixdisplay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        GALLERY
      </motion.div>
      <motion.div
        className="px-16 py-10 grid grid-cols-2 md:grid-cols-4 gap-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }} // Delay the animation to start after the gallery title
      >
        {gallery.map((imageRow, rowIndex) => (
          <div key={rowIndex} className="grid gap-4">
            {imageRow.map((image, imageIndex) => (
              <motion.div
                key={imageIndex}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.1, rotateY: 10}} // Tambahkan prop whileHover untuk animasi saat kursor masuk
                whileTap={{ scale: 1}}
              >
                <img className="h-auto max-w-full rounded-lg" src={image} alt="" />
              </motion.div>
            ))}
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Gallery;
