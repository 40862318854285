// src/components/Section.js
import React from 'react'; // Replace with your actual image path
import sectionimage from '../assets/a.png';

const Section = () => {
  return (
    <section className="">
    <div className="container flex items-center py-24 mx-auto">
      {/* Left Image */}
      <div className="relative flex items-center justify-center flex-1">
        <h1 className="text-9xl text-white font-bold font-outline-2 absolute top-[-13.5rem] font-wixdisplay" style={{ zIndex: '1' }}>
          ABOUT
        </h1>
        {/* Added image with src attribute */}
        <h1 className=" font-bold absolute top-[-17.5rem] font-wixdisplay scale-75" style={{ zIndex: '2' }}>
          <img src={sectionimage} alt="About" />
        </h1>
      </div>
      
        {/* Right Section Content */}
        <div className="flex-1 mx-8 ">
          <p className="mb-3 text-xl tracking-widest text-justify text-black dark:text-gray-400 font-wixfont">Aiiromori adalah kelompok lingkar karya kreatif yang dibentuk tahun 2014 oleh Unit Kebudayaan Jepang Universitas Pembangunan Jaya (UKJ UPJ) sebagai merek unit usaha dengan produk dan pasar yang spesifik, yaitu ‘Self-published works’ atau populer di pop kultur jejepangan sebagai ‘Doujinshi’. </p>
          <p className="text-xl tracking-widest text-justify text-black dark:text-gray-400 font-wixdfont">Aiiromori memiliki spesialisasi dalam produksi fan merchandise dari subkultur Anime, Komik, dan Game (ACG). Aiiromori juga telah berpartisipasi ke dalam lebih dari 10 perhelatan Comic Frontier, sebuah event dwi-tahunan yang merupakan ‘Pasar Doujinshi’ terbesar di Indonesia. Aiiromori secara konsisten telah membawakan banyak karya-karya dengan kualitas yang semakin berkembang dan semakin beragam.</p>
        </div>
      </div>
    </section>
  );
};

export default Section;