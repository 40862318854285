import { Carousel } from "@material-tailwind/react";
import carousel1 from '../assets/1.jpg';
import carousel2 from '../assets/2.jpg';
import carousel3 from '../assets/3.jpg';
import carousel4 from '../assets/4.png';
import carousel5 from '../assets/5.png';
import carousel6 from '../assets/6.jpg';


export function CarouselCustomNavigation() {
  // Array berisi informasi untuk setiap slide dalam carousel
  const slides = [
    { src: carousel1, alt: "Slide 1" },
    { src: carousel2, alt: "Slide 2" },
    { src: carousel3, alt: "Slide 3" },
    { src: carousel4, alt: "Slide 4" },
    { src: carousel5, alt: "Slide 5" },
    { src: carousel6, alt: "Slide 6" }
  ];

  // Fungsi untuk mengacak urutan array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Mengacak urutan array slides
  const shuffledSlides = shuffleArray(slides);

  return (
    <Carousel
      className="h-[34rem] mt-36"
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute z-50 flex gap-2 bottom-4 left-2/4 -translate-x-2/4">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
      {/* Menggunakan slides yang sudah diacak */}
      {shuffledSlides.map((slide, index) => (
        <img
        key={index}
        src={slide.src}
        alt={slide.alt}
        className="object-cover mx-auto  w-full h-full" // Menggunakan margin-top untuk mengatur posisi ke bawah
      />
      ))}
    </Carousel>
  );
}
