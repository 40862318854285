// src/components/Navbar.js
import React from 'react';
import logo from '../assets/logo.svg';

const Navbar = () => {
  return (
    <nav className="fixed top-0 z-50 w-full pb-2 bg-white ">
      <div className="container flex items-center justify-between mx-auto">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="w-64 h-32 mr-2" />
        </div>
        <div className="hidden space-x-8 text-lg md:flex font-wixdisplay">
          <button type="button" className=" hover:font-bold">
            Home
          </button>
          <button type="button" className=" hover:font-bold">
            About
          </button>
          <button type="button" className=" hover:font-bold">
            Artist
          </button>
          <button type="button" className=" hover:font-bold">
            Contact Us
          </button>
        </div>
        <div className="md:hidden">
          <button type="button" className="text-white">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
