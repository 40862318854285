import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'; // Mengimpor ikon dari Font Awesome
import pixiv from '../assets/pixiv.svg';


const Card = ({ title, category, description, imageSrc, fb, insta, twitter, pixivlink }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.1, rotateY: 10}} // Tambahkan prop whileHover untuk animasi saat kursor masuk
      whileTap={{ scale: 0.9 }}
      className="max-w-xl mx-auto mb-10 overflow-hidden bg-purple-500 rounded-md shadow-md"
      style={{ width: '550px' }}
    >
      <div className="md:flex">
        <div className="md:flex-shrink-0">
          <img
            className="object-cover w-full h-48 md:w-48"
            src={imageSrc}
            alt={title}
          />
        </div>
        <div className="p-8">
          <p href="#/" className="block mt-1 text-5xl font-bold leading-tight text-white font-wixdisplay">
            {title}
          </p>
          {/* Menambahkan ikon-ikon sosial media */}
          <div className="flex mt-4">
            <a href={fb} className="mr-4 text-white text-xl hover:text-purple-100" target="_blank" rel="noreferrer">
            <FaFacebook />
            </a>
            <a href={insta} className="mr-4 text-white text-xl hover:text-purple-100" target="_blank" rel="noreferrer">
            <FaInstagram />
            </a>
            <a href={twitter} className="mr-4 text-white text-xl hover:text-purple-100" target="_blank" rel="noreferrer">
            <FaTwitter />
            </a>
            <a href={pixivlink} className="mr-4 text-white text-xl h-5" target="_blank" rel="noreferrer">
            <img src={pixiv} alt='test' className='text-white text-xl h-5'></img>
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Card;
